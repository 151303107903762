.App {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100vh;
  .Editor {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    border-right: 1px solid black;

    .Playground {
      grid-area: 1 / 1 / span 1 / span 2;
    }

    .Sidebar {
      grid-area: 1 / 1 / span 1 / span 1;
    }

    .MidArea {
      grid-area: 1 / 2 / span 1 / span 1;
    }
  }

  .Preview {

  }
}