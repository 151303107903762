* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  height: 100%;
}

.shadow {
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1);
}